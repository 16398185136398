.dropdown_btn[class~="componnt_lib_toggle_btn_active"]:focus {
  border-color: theme("colors.interactive-secondary-default") !important;
}

/* .country_wrapper [class~="dropdown_component_library"][class~="filled"],
.country_wrapper
  [class~="dropdown_component_library"][class~="focused_without_selectitem"] {
  outline: 2px solid #368fe9;
  outline-offset: 4px;
} */

.dobWrapper {
  /* @apply h-14 border-border-accent-1-primary !important; */
}

.dobWrapperError {
  /* @apply h-14 border-border-critical-primary !important; */
}

.dobErrorMessage {
  @apply text-[14px] font-semibold  text-border-critical-primary leading-[21px] p-0 mt-spacing-xxs !important;
}

.country_wrapper [class~="selected"] {
  border-width: 2px;
}

.country_wrapper [class~="dropdown_component_library"][class~="filled"] {
  border-width: 2px;
}

.country_wrapper [class~="dropdown_component_library"][class~="hovered"] {
  box-shadow: 0px 0px 0px 2px rgba(40, 86, 110, 0.4);
  background: theme("colors.surface-neutral-option-2");
  border-width: 2px;
}
.country_wrapper div:nth-child(1) {
  width: 100%;
  height: 100%;
  justify-content: center;
}

.country_wrapper div:nth-child(2) {
  justify-content: center;
  align-items: center;
}

.country_wrapper [class~="hint_label"] {
  display: none;
}

.country_wrapper [class~="icon_and_placeholdertext"] {
  display: flex;
}

.country_wrapper [class~="placeholder_text_component_library"] {
  @apply !font-body-font !text-text-functional-subtle leading-[24px] text-left !ml-0 font-semibold bg-transparent;
}

.country_wrapper [class~="dropdown_component_library"] {
  width: 100%;
}

.country_wrapper
  [class~="dropdown_component_library"]
  [class~="country-name"]:hover {
  @apply bg-transparent;
}

.country_wrapper [class~="dropdown_component_library"] [class~="country-name"] {
  @apply text-text-functional  pl-0;
}

.country_wrapper [class~="country-name"] {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.country_wrapper [class~="country-name"]:hover {
  background: theme("colors.surface-neutral-option-2");
}

.country_wrapper:not(:has([class~="country-name"])):hover
  [class~="dropdown_component_library"] {
  @apply lg:pl-[23px] pl-[15px] pr-[15px];
}

.country_wrapper:not(:has([class~="country-name"])):hover
  [class~="placeholder_text_component_library"] {
  @apply relative top-[-1px];
}

.country_wrapper:not(:has([class~="country-name"])):hover
  [class~="iconCompLibrary_chevronDownIcon_svg_"] {
  @apply relative top-[-1px];
}

.country_wrapper [class~="dropdown_component_library"] > div {
  @apply relative;
}

.country_wrapper [class~="arrow_head_icon_component_library"] {
  @apply absolute right-0;
}

.country_wrapper button > div {
  justify-content: space-between;
}

.country_wrapper button > div > div {
  justify-content: start !important;
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDateCalendar-root"]
  [class~="MuiPickersDay-root"][class~="Mui-disabled"] {
  @apply !text-surface-neutral-option-5;
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDayCalendar-header"]
  [class~="MuiTypography-caption"] {
  @apply !font-body-font !text-text-body leading-[24px];
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDateCalendar-root"]
  [class~="MuiPickersDay-root"][class~="Mui-disabled"]:hover {
  @apply !bg-transparent !font-normal;
}

.date_picker_wrapper
  [class~="SingleDatePicker-module_defaultClass__5O1TZ"][type="button"] {
  -webkit-appearance: initial;
  appearance: initial;
}

.date_picker_wrapper
  [class~="SingleDatePicker-module_defaultClass__5O1TZ"]
  [class~="MuiTextField-root"]
  [class~="MuiInputLabel-root"] {
  @apply lg:!pl-[8px] !pl-[0] !pr-0 max-w-[106px] w-auto;
}

.date_picker_wrapper
  [class~="SingleDatePicker-module_defaultClass__5O1TZ"]
  [class~="MuiTextField-root"]
  [class~="MuiInputLabel-root"][class~="MuiInputLabel-shrink"] {
  @apply !pl-[4px] !pr-[4px];
}

.date_picker_wrapper
  [class~="SingleDatePicker-module_defaultClass__5O1TZ"]
  [class~="MuiTextField-root"]
  [class~="MuiInputBase-root"] {
  @apply pr-0;
}

.date_picker_wrapper
  [class~="SingleDatePicker-module_defaultClass__5O1TZ"]
  [class~="MuiTextField-root"]
  [class~="MuiInputBase-root"]
  [class~="MuiOutlinedInput-notchedOutline"] {
  @apply p-0;
}

.date_picker_wrapper
  [class~="SingleDatePicker-module_defaultClass__5O1TZ"]
  [class~="MuiInputBase-root"]
  [class~="MuiInputBase-input"] {
  @apply pt-[16px] pb-[16px] !font-body-font lg:pl-spacing-l pl-spacing-m leading-[24px] text-2xs text-text-functional;
}

.date_picker_wrapper
  [class~="SingleDatePicker-module_defaultClass__5O1TZ"]
  button {
  @apply !h-auto;
}

.date_picker_wrapper
  [class~="MuiDateCalendar-root"][class~="SingleDatePicker-module_calendarContainer__miVSF"] {
  @apply !p-0 max-h-[360px];
}

.date_picker_wrapper
  [class~="SingleDatePicker-module_defaultClass__5O1TZ"][class~="thick-border"]
  [class~="iconCompLibrary_calender_svg_"] {
  @apply relative left-[1px];
}

.date_picker_wrapper
  [class~="SingleDatePicker-module_defaultClass__5O1TZ"][class~="thick-border"]
  [class~="MuiFormControl-root"]
  [class~="MuiInputLabel-root"] {
  @apply left-[-1px] top-[-1px];
}

.trvellers_form [class~="field-block"] {
  @apply md:mb-0 mb-spacing-m bg-surface-neutral-option-1 md:px-0 px-spacing-m;
}

.trvellers_form [class~="field-block"]:not(:first-of-type) {
  @apply py-spacing-m md:py-spacing-l lg:py-spacing-xl  md:rounded-none rounded-radius-lg border-t border-border-subdued;
}

.trvellers_form [class~="field-block"]:first-of-type {
  @apply md:pb-spacing-l pb-spacing-m pt-px md:pt-0 md:rounded-none rounded-b-radius-lg;
}

.trvellers_form [class~="field-block"]:only-of-type {
  @apply md:pb-spacing-xxl pb-spacing-m mb-0;
}

.trvellers_form [class~="field-block"]:last-of-type {
  margin-bottom: 0;
}

.trvellers_form [class~="field-block"]:has([class~="remove-button"]) {
  @apply md:pb-0 pb-spacing-m;
}

.trvellers_form
  [class~="field-block"]:not(:has([class~="remove-button"])):last-of-type {
  @apply lg:pb-spacing-m md:pb-spacing-m pb-spacing-m;
}

.trvellers_form
  [class~="field-block"]:not(:has([class~="remove-button"])):only-of-type {
  @apply md:pb-spacing-xxl pb-spacing-m mb-0;
}

.trvellers_form [class~="field-block"] [class~="remove-button"] {
  @apply md:pt-spacing-l pt-spacing-xs;
}

.trvellers_form [class~="add-more-btn-wrapper"] {
  @apply lg:pb-spacing-xl pt-spacing-l md:pb-spacing-xxl 
  md:my-0 my-spacing-m border-t md:border-border-subdued pb-spacing-l;
  margin-top: 24px;
}

.trvellers_form [class~="text-input-block"] label {
  transition:
    color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  transform: translate(14px, 16px) scale(1) !important;
  @apply lg:!left-[10px] !left-[2px] !top-0;
}

.trvellers_form [class~="text-input-block"][class~="hover-text-input"] label {
  @apply lg:!left-[9px] !top-[-1px];
}

@-moz-document url-prefix() {
  .trvellers_form [class~="text-input-block"][class~="hover-text-input"] label {
    @apply lg:!left-[8.5px];
  }
}

.trvellers_form [class~="text-input-block"] input {
  @apply lg:!pl-spacing-l lg:!pr-spacing-l !pl-spacing-m !pr-spacing-m;
}

.trvellers_form
  [class~="text-input-block"][class~="hover-text-input"][class~="has-value"]
  label {
  @apply !left-0 !top-0;
}

.trvellers_form [class~="active-text-input"] label,
.trvellers_form [class~="has-value"] label {
  transform: translate(0, -14px) scale(0.75) !important;
  @apply !left-0;
}

.traveller_btn_wrapper [class~="btn-group-wrapper"] {
  @apply m-0 gap-spacing-s;
}

.traveller_btn_wrapper
  [class~="btn-group-wrapper"]
  [class~="componnt_lib_toggle_btn"] {
  @apply mb-0;
}

.date_picker_wrapper [class~="customize_calender_wrapper"] {
  @apply absolute top-[56px] z-50 bg-transparent;
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDateCalendar-root"]
  [class~="MuiDateRangePickerDay-day"] {
  @apply !font-body-font !text-text-functional;
}
.date_picker_wrapper
  [class~="MuiDateCalendar-root"]
  [class~="MuiPickersDay-root"] {
  @apply !font-body-font leading-[24px];
}

.date_picker_wrapper
  [class~="MuiDateCalendar-root"]
  [class~="MuiPickersDay-root"][class~="Mui-selected"] {
  @apply font-semibold;
}

.date_picker_wrapper
  [class~="MuiDateCalendar-root"]
  [class~="MuiPickersDay-root"][class~="Mui-selected"][class~="Mui-disabled"] {
  @apply !bg-transparent;
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDateCalendar-root"]
  [class~="MuiDateRangePickerDay-day"]:hover {
  @apply !rounded-radius-lg bg-interactive-primary-hovered border-interactive-primary-hovered;
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDateCalendar-root"]
  [class~="MuiDateRangePickerDay-day"][class~="MuiDateRangePickerDay-dayInsideRangeInterval"] {
  @apply font-semibold;
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDayCalendar-slideTransition"] {
  @apply overflow-y-hidden w-full;
}

.date_picker_wrapper [class~="MuiPickersCalendarHeader-root"] {
  @apply mt-[22px];
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiPickersCalendarHeader-label"] {
  @apply mr-0 !font-body-font leading-[27px];
}

.date_picker_wrapper
  [class~="MuiFormLabel-root"][class~="MuiInputLabel-shrink"] {
  @apply !text-text-functional;
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDateCalendar-root"]
  [class~="MuiButtonBase-root"][class~="MuiPickersArrowSwitcher-button"] {
  @apply mb-[10px];
}
.date_picker_wrapper [class~="MuiDayCalendar-header"] {
  @apply pt-[5px];
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDateCalendar-root"]
  [class~="MuiDayCalendar-weekDayLabel"] {
  @apply !font-body-font leading-[24px];
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiYearCalendar-root"] {
  @apply w-full;
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDateCalendar-root"]
  [class~="MuiButtonBase-root"][class~="MuiPickersDay-root"]:hover {
  @apply rounded-radius-lg font-semibold;
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDateCalendar-root"] {
  @apply ml-0;
}

.input_wrapper input {
  @apply lg:!px-spacing-l !px-spacing-m;
}

@media (min-width: 600px) {
  .date_picker_wrapper [class~="customize_calender_wrapper"] {
    @apply !w-[304px];
  }

  .date_picker_wrapper
    [class~="customize_calender_wrapper"]
    [class~="MuiDayCalendar-slideTransition"] {
    @apply min-w-[302px] min-h-[254px];
  }
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDateCalendar-root"]
  [class~="MuiMonthCalendar-root"] {
  @apply border-none shadow-none !w-auto !max-h-[280px];
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDateCalendar-root"]
  [class~="MuiMonthCalendar-root"]
  [class~="MuiPickersMonth-root"]
  [class~="MuiPickersMonth-monthButton"][class~="Mui-selected"] {
  @apply bg-interactive-primary-default-1 text-text-on-primary;
}

.trvellers_form
  .date_picker_wrapper
  [class~="DateInput-module_showBorder__ddQ-h"] {
  @apply text-text-functional text-[12px] leading-[16px] font-medium absolute top-[-8px] left-[8px] pl-[4px] pr-[4px];
}

.trvellers_form
  .date_picker_wrapper
  [class~="DateInput-module_dobInput__tW4iD"] {
  @apply font-semibold;
}

.trvellers_form
  .date_picker_wrapper
  [class~="DateInput-module_dobContainer__hWHk9"] {
  @apply lg:pl-spacing-l pl-spacing-m lg:pr-spacing-l pr-spacing-m h-14;
}

.trvellers_form
  .date_picker_wrapper
  [class~="TravellerForm_dobErrorMessage___rBvV"] {
  @apply !text-[14px] !text-text-critical !font-semibold !leading-[21px] !p-0;
}

.trvellers_form
  .date_picker_wrapper
  [class~="DateInput-module_dobAciveContainer__N1hGf"] {
  @apply !h-14;
}

.trvellers_form
  .date_picker_wrapper
  [class~="DateInput-module_showActiveBorder__hUl8U"] {
  @apply !top-[-28px];
}

.trvellers_form
  .date_picker_wrapper
  [class~="DateInput-module_MsgClass__77awu"] {
  @apply !p-0;
}

@media (max-width: 600px) {
  .date_picker_wrapper
    [class~="customize_calender_wrapper"][class~="single_date_picker_wrapper"] {
    @apply !w-[calc(100vw-72px)] ml-[-4px];
  }
  .date_picker_wrapper
    [class~="customize_calender_wrapper"][class~="single_date_picker_wrapper"]
    [class~="MuiDateCalendar-root"] {
    @apply !w-full;
  }

  .date_picker_wrapper
    [class~="customize_calender_wrapper"][class~="single_date_picker_wrapper"]
    [class~="MuiDateCalendar-root"]
    [class~="MuiPickersCalendarHeader-root"]
    [class~="MuiPickersFadeTransitionGroup-root"] {
    @apply w-full;
  }

  .date_picker_wrapper
    [class~="customize_calender_wrapper"][class~="single_date_picker_wrapper"]
    [class~="MuiDateCalendar-root"]
    [class~="MuiPickersFadeTransitionGroup-root"] {
    @apply w-[calc(100%-2px)];
  }
}

.dropdown_focused {
  outline: 2px solid theme("colors.interactive-focus-default");
  outline-offset: 2px;
  border-radius: 8px;
}

.trvellers_form
  .date_picker_wrapper
  [class~="DateInput-module_dobLabel__axDHE"] {
  color: rgb(79, 79, 79);
}

.trvellers_form
  .date_picker_wrapper
  [class~="DateInput-module_fontAvanti__p6bUj"] {
  color: "#272427" !important;
}

.dobManualEnterField::-webkit-outer-spin-button,
.dobManualEnterField::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dobManualEnterField {
  -moz-appearance: textfield;
}
