.trustpilotWrapper [class~="frontTextPerformance"] {
  @apply text-surface-neutral-option-2 font-body-font;
}

.trustpilotWrapper [class~="middleTextScore"] {
  @apply text-surface-neutral-option-2 font-body-font;
}

.trustpilotWrapper [class~="endTextReviews"] {
  @apply text-surface-neutral-option-2 font-body-font;
}

.trustpilotSupportRichText {
  @apply text-[18px] md:my-spacing-s sm:mt-spacing-xs;
}

.trustpilotSupportRichText a {
  @apply text-inherit;
}

.trustpilotSupportRichText a:hover {
  @apply text-inherit no-underline;
}
